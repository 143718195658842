<template>
  <div class="modal fade" id="modal-wows">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Wows</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Type</th>
              <th>Review</th>
              <th>Charity</th>
              <th>Donate Amount</th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td>Home Stars</td>
              <td>
                <input class="form-control form-control-sm"
                       v-model="formWows.homeStars.review"
                       :disabled="selectedJob && selectedJob.all_wows.find(w => w.type === 'Home Stars')"
                       type="text"
                       placeholder="Review">
              </td>
              <td>
                <select class="form-control form-control-sm"
                        :disabled="!formWows.homeStars.review || (selectedJob && selectedJob.donates.find(w => w.type === 'Home Stars'))"
                        @change="changeCharity('homeStars')"
                        v-model="formWows.homeStars.charity_id">
                  <option value="">Select Charity</option>
                  <option :value="charity.id"
                          v-for="charity in charities"
                          :key="'charity_'+charity.id">{{ charity.name }}</option>
                </select>
              </td>
              <td>
                <input type="text"
                       :disabled="!formWows.homeStars.review || (selectedJob && selectedJob.donates.find(w => w.type === 'Home Stars'))"
                       class="form-control form-control-sm"
                       v-model="formWows.homeStars.donate_amount">
              </td>
            </tr>
            <tr>
              <td>Google Reviews</td>
              <td>
                <input class="form-control form-control-sm"
                       :disabled="selectedJob && selectedJob.all_wows.find(w => w.type === 'Google Reviews')"
                       v-model="formWows.googleReviews.review"
                       type="text"
                       placeholder="Review">
              </td>
              <td>
                <select class="form-control form-control-sm"
                        :disabled="!formWows.googleReviews.review || (selectedJob && selectedJob.donates.find(w => w.type === 'Google Reviews'))"
                        @change="changeCharity('googleReviews')"
                        v-model="formWows.googleReviews.charity_id">
                  <option value="">Select Charity</option>
                  <option :value="charity.id"
                          v-for="charity in charities"
                          :key="'charity_'+charity.id">{{ charity.name }}</option>
                </select>
              </td>
              <td>
                <input type="text"
                       :disabled="!formWows.googleReviews.review || (selectedJob && selectedJob.donates.find(w => w.type === 'Google Reviews'))"
                       class="form-control form-control-sm"
                       v-model="formWows.googleReviews.donate_amount">
              </td>
            </tr>
            <tr :class="{'table-secondary': selectedJob && selectedJob.all_wows.find(w => w.type === '5 Around')}">
              <td>5 Around</td>
              <td>
                <input class="form-control form-control-sm"
                       :disabled="selectedJob && selectedJob.all_wows.find(w => w.type === '5 Around')"
                       v-model="formWows.fiveAround.review"
                       type="text"
                       placeholder="Review">
              </td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-dark" data-dismiss="modal">Close</button>
          <button type="button"
                  class="btn btn-primary"
                  @click.prevent="submitWows">Save changes</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      selectedJob: null,
      charities: [],
      formWows: {
        homeStars: {
          to: '',
          review: '',
          charity_id: '',
          donate_amount: ''
        },
        googleReviews: {
          to: '',
          review: '',
          charity_id: '',
          donate_amount: ''
        },
        fiveAround: {
          to: '',
          review: ''
        },
      },
    }
  },
  created() {
    this.loadCharities();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-wows'));
  },
  methods: {
    show(job) {
      this.formWows.homeStars.review = '';
      this.formWows.googleReviews.review = '';
      this.formWows.fiveAround.review = '';

      this.formWows.homeStars.donate_amount = '';
      this.formWows.googleReviews.donate_amount = '';

      this.formWows.homeStars.charity_id = '';
      this.formWows.googleReviews.charity_id = '';

      this.formWows.homeStars.to = '';
      this.formWows.googleReviews.to = '';
      this.formWows.fiveAround.to = '';

      this.selectedJob = job

      job.all_wows.forEach(wow => {
        if (wow.type === 'Home Stars') {
          this.formWows.homeStars.review = wow.review;
        } else if (wow.type === 'Google Reviews') {
          this.formWows.googleReviews.review = wow.review;
        } else if (wow.type === '5 Around') {
          this.formWows.fiveAround.review = wow.review;
        }
      })

      job.donates.forEach(donate => {
        if (donate.type === 'Home Stars') {
          this.formWows.homeStars.donate_amount = donate.amount;
          this.formWows.homeStars.charity_id = donate.charity_id;
        } else if (donate.type === 'Google Reviews') {
          this.formWows.googleReviews.donate_amount = donate.amount;
          this.formWows.googleReviews.charity_id = donate.charity_id;
        }
      })

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    changeCharity(type) {
      if (this.formWows[type].charity_id !== '') {
        this.formWows[type].donate_amount = this.charities.find(c => c.id === this.formWows[type].charity_id).default_amount;
      }
    },
    submitWows() {
      this.axios.post('/jobs/wow/' + this.selectedJob.id, this.formWows)
        .then(() => {
          this.$emit('submitted')
          this.hide();
        })
        .catch(() => {})
    },
    loadCharities() {
      this.axios.get('/charities')
        .then((response) => {
          this.charities = response.data.data;
        });
    }
  }
}
</script>